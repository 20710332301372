<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(coruses)="data">
        <div style="margin: 6px;">
          <span
            title="View"
            style="text-wrap: nowrap;"
            class="cursor-pointer hoverbtn"
            @click="
              $router.push({
                name: 'courses',
                params: { C_id: data.item.id }
              })
            "
          >
            Add , Edit and show Courses
          </span>
        </div>

      </template>

      <template #cell(info_details)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'info-details-engineers',
                  params: { id: data.item.id }
                })
              "
            />
          </div>
        </slot>
      </template>

      <template #cell(status_and_working)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'certificates-status-work',
                  params: { id: data.item.id }
                })
              "
            />
          </div>
        </slot>
      </template>
      <template #cell(photo)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <b-img
            v-bind="mainProps"
            rounded="circle"
            :src="data.item.photo"
            alt="Engineer photo"
          />
        </slot>
      </template>

    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      mainProps: {
        blankColor: '#777', width: 75, height: 75, class: 'm1',
      },
      APIURL: 'engineer',
      // addType: 'Add engineer',
      // addComponentName: 'add-engineer',
      editComponent: 'edit-engineers',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'photo', label: 'photo' },
        { key: 'name', label: 'Name Ar' },
        { key: 'email', label: 'Email' },
        { key: 'department_name', label: 'Department' },
        { key: 'job_description', label: 'Job Description' },
        { key: 'joined_at', label: 'Joined' },
        { key: 'load', label: 'Load' },
        { key: 'office.name', label: 'Office' },
        { key: 'busyFrom', label: 'Busy From' },
        { key: 'busyTo', label: 'Busy To' },
        { key: 'info_details', label: 'Info Details' },
        // { key: 'certificates_status', label: 'Certificates Status' },
        // { key: 'working_certificates', label: 'Working Certificates' },
        { key: 'status_and_working', label: 'Status & Working Certificates' },
        { key: 'coruses', label: 'Coruses' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'engineer?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style>
.hoverbtn{
  border: 1px solid;
  padding: 5px !important ;
  border-radius: 5px;
}
.hoverbtn:hover{
  border: 1px solid white;
  background-color: black;
  color: white;
}</style>
