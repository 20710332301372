var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"api-url":_vm.apiUrl,"edit-component":_vm.editComponent,"delete-content":false,"block-content":false,"edit-content":true,"view-content":false,"columns":_vm.columns,"type":_vm.type},scopedSlots:_vm._u([{key:"cell(coruses)",fn:function(data){return [_c('div',{staticStyle:{"margin":"6px"}},[_c('span',{staticClass:"cursor-pointer hoverbtn",staticStyle:{"text-wrap":"nowrap"},attrs:{"title":"View"},on:{"click":function($event){return _vm.$router.push({
              name: 'courses',
              params: { C_id: data.item.id }
            })}}},[_vm._v(" Add , Edit and show Courses ")])])]}},{key:"cell(info_details)",fn:function(data){return [_vm._t("actions",function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.$router.push({
                name: 'info-details-engineers',
                params: { id: data.item.id }
              })}}})],1)]},null,data)]}},{key:"cell(status_and_working)",fn:function(data){return [_vm._t("actions",function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.$router.push({
                name: 'certificates-status-work',
                params: { id: data.item.id }
              })}}})],1)]},null,data)]}},{key:"cell(photo)",fn:function(data){return [_vm._t("actions",function(){return [_c('b-img',_vm._b({attrs:{"rounded":"circle","src":data.item.photo,"alt":"Engineer photo"}},'b-img',_vm.mainProps,false))]},null,data)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }